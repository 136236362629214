import React, { Component } from 'react';
import logo from '../assets/img/bark-logo.png';
import { forgotPassword } from '../services/authService';
class ForgotPassword extends Component{

    state = {
        email: '',
        errMsg: '',
        successMsg: ''
    }

    handleEmailChange = ({ currentTarget:input }) => {
        this.setState({'email': input.value});
        
    }
    handleSubmit = async(e) => {
        console.log(this.state.email);
        const response = await forgotPassword(this.state.email);
        console.log(response, response.message);
        if(response.status == true){
            this.setState({'successMsg': response.message});
            this.setState({'errMsg': ''});
            window.location = '/verify-token?token='+response.data.api_token;
        }else{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': response.message});
        }

        setTimeout(() =>{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': ''});
        }, 10000);
    }
    render(){
        return(
            <section className="forgot-password">
                <div className="container">
                    <div className="foam">
                        <div className="img-cont">
                            <img src={logo} className="img-fluid" />
                        </div>
                        <div className="text-cont">
                            <h2>Forgot Password</h2>
                            <p>Please enter your email address so we can send OTP Code</p>
                        </div>
                        <div className="user-login">
                            {this.state.errMsg && <div className="alert alert-danger">{this.state.errMsg}</div>}
                            {this.state.successMsg && <div className="alert alert-success">{this.state.successMsg}</div>}
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.4 1H13.6C14.37 1 15 1.63 15 2.4V10.8C15 11.57 14.37 12.2 13.6 12.2H2.4C1.63 12.2 1 11.57 1 10.8V2.4C1 1.63 1.63 1 2.4 1Z" stroke="#EE3124" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 2.3999L8 7.2999L1 2.3999" stroke="#EE3124" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <input type="email" className="form-control" placeholder="Email Address"
                                 aria-label="Email Address" aria-describedby="addon-wrapping" onChange={this.handleEmailChange}/>
                            </div>

                            <div className="btn-login">
                                <button type="button" className="btn btn-danger" onClick={this.handleSubmit}>Send Email</button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ForgotPassword;