import http from './httpService';
import { getJwt } from './authService';

const apiEndpoint = http.apiEndpoint;

http.setJwt(getJwt());

export function getCalendar(date){
    return http.get(apiEndpoint+'/employer/tasks/calender', {params : {date: date}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getWeekCalendar(days=0){
    return http.get(apiEndpoint+'/employer/tasks/week-calender', {params : {days: days}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getJobTitles(){
    return http.get(apiEndpoint+'/employer/tasks/job-titles')
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getTasks(id){
    return http.get(apiEndpoint+'/employer/tasks/subtasks', {params : {id: id}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function updateTaskStatus(id, status, feedback="", someoneElseId=null, someoneElseName=null){
    return http.post(apiEndpoint+'/employer/tasks/mark-complete', 
        {id: id, status: status, feedback: feedback, someone_else_id: someoneElseId, someone_else_name: someoneElseName}
    ).then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getCurrentWeekDays(days=0){
    return http.get(apiEndpoint+'/weekdays', {params : {days: days}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getEmployees(){
    return http.get(apiEndpoint+'/employees')
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}