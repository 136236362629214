import http from './httpService';
import { getJwt } from './authService';
const apiEndpoint = http.apiEndpoint;

http.setJwt(getJwt());

export function getProfile(){
    return http.get(apiEndpoint+'/user/profile')
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}