import parse from 'html-react-parser';

export default function EmployeeStatsTabContent(props){
    const weekDays = props.weekDays;
    const tabData = props.tabData;
    let listContents = "";    
    var selectedEmp = JSON.parse(localStorage.getItem('emp_active_box'));
    weekDays.map((weekDay, index)=>{
        let activeClass = '';
        let showClass = '';
        let jobTitles = "";
        let users = "";

        if(selectedEmp != undefined){
            if(selectedEmp.pos != undefined && selectedEmp.pos == 'pos-'+index){
                activeClass = 'active';
                showClass = 'show'
            }
        }else{
            if(weekDay.current_day == true){   
                activeClass = 'active';
                showClass = 'show'
            }
        }

        if(tabData[index] != undefined){
            Object.values(tabData[index]).map((obj, i) => {
                let marginClass = i > 0 ? " mt-3": "";
                let activeBox = "";
                if(selectedEmp != undefined){
                    if(selectedEmp.user != undefined && selectedEmp.user == 'user_'+index+'_'+i){
                        activeBox = 'active-box';
                    }else if(index != selectedEmp.ci && i==0){
                        activeBox = 'active-box';
                    }
                }else{
                    activeBox = i > 0 ? "" : 'active-box';
                }
                users += `
                <div className="box user-box${marginClass} ${activeBox}" id="user_${index}_${i}" data-content="content_${index}_${i}" data-uindex="${i}" data-tcontent="${index}">
                    <div className="card">
                        <div className="card-body">
                            <h5>${obj.name}</h5>
                            <div className="row">
                                <div className="col-lg-8 col-md-12   col-sm-6 pr-0">
                                    <div className="d-flex">
                                        <span className="shift">SHIFT</span>
                                        <span className="first-shift">${obj.shift}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span className="time">TIMINGS</span>
                                        <span className="pm">${obj.shift_time}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span className="category">CATEGORY</span>
                                        <span className="handler">${obj.role}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span className="type">JOB TYPE</span>
                                        <span className="periodic">${obj.job_type}</span>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 text-end col-sm-6">
                                    <div className="circle">
                                        ${obj.completed_percent}
                                        <p>Percent</p>
                                    </div>
                                    <span className="achive">Achived</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
            });
        }
        if(tabData[index] != undefined){
            Object.values(tabData[index]).map((obj, i) => {
                let display = "none";
                if(selectedEmp != undefined){
                    if(selectedEmp.content != undefined && selectedEmp.content == 'content_'+index+'_'+i){
                        display = 'block';
                    }else if(index != selectedEmp.ci && i==0){
                        display = 'block';
                    }
                }else{
                    display = i>0 ? 'none': 'block'
                }

                jobTitles += `<div id="content_${index}_${i}" className="text-content task-content${index}" style="display: ${display}">
                <h3>Task Details</h3>
                <ul className="detail-list">`
                if(obj.task_details.length>0){
                    obj.task_details.map((obj)=>{
                        let statusColor = (obj.completed_at != null) ? '#29B86D' : '#979797';
                        let svg = "";
                        if(obj.verified == 1){
                            svg  = `<svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="11" fill="${statusColor}"/>
                                        <path d="M7 8.2L9.86364 11L15 6" stroke="#FCFCFC" stroke-width="2"/>
                                        <path d="M7 12.2L9.86364 15L15 10" stroke="#FCFCFC" stroke-width="2"/>
                                    </svg>`;
                        }else{
                            svg = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9" cy="9" r="9" fill="${statusColor}"/>
                                        <path d="M5.72754 8.34541L8.07051 10.6363L12.273 6.54541" stroke="#FCFCFC"/>
                                    </svg>`;
                        }
                        jobTitles += `<li>
                            <div className="d-flex">
                                <div className="left">
                                <a href="/tasks/${obj.id}/employee">${obj.task}</a>
                                </div>
                                <div className="right">${svg}</div>
                            </div>
                        </li>`
                    });
                }else{
                    jobTitles += '<div className="no-data"><p>No Record Found</p></div>';
                }
                jobTitles +='</ul></div>';
            });
        }

        listContents+=     
        `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel" aria-labelledby="tab-${index}">
            <div className="card-header" role="tab" id="heading-${index}">
                <h5 className="mb-0">
                    <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true" aria-controls="collapse-${index}">
                        <div className="number">${weekDay.day}</div>
                        <div className="day">${weekDay.week_day}</div>
                    </a>
                </h5>

            </div>
            <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-${index}">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4 pr-0">
                            ${users}
                        </div>
                        <div className="col-md-8">
                           ${jobTitles}
                        </div>
                    </div>
                </div>
            </div>
        </div>`
            
    });

    return (parse(listContents))
}