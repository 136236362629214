import StatsTabContent from "./statsTabContent";
import StatsTabItem from "./statsTabItem";

export default function StatsTab(props){

    const weekDays = props.weekDays;
    return(
        <div className=" responsive-tabs">
            <ul className="nav nav-tabs" role="tablist">
            {props.calenderData && <StatsTabItem weekDays={weekDays} tabData={props.calenderData} />}
                {/* <li className="nav-item">
                    <a id="tab-A" href="#pos-A" className="nav-link active" data-bs-toggle="tab" role="tab">
                        <div className="number">19</div>
                        <div className="day">Monday</div>
                    </a>
                </li> */}
            </ul>
            <div id="content-1" className="tab-content" role="tablist">
                {props.calenderData && <StatsTabContent weekDays={weekDays} tabData={props.calenderData} />}
            </div>
        </div>

    );
    
}