import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import { getTasks } from '../services/jobService';
import { approveAllTasks } from '../services/managerServices';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Task extends Component{
    constructor(props) {
        super(props);
    }
    state = {
        jobData: {},
        isEmpManger: false,
        prevoiusUrl: ""
    }


    async componentDidMount(){
        let jobId = this.props.match.params.id;
        let isEmpManger = this.props.match.params.employee;
        const jobTitles = await getTasks(jobId);
        this.setState({jobData: jobTitles.data});
        if(isEmpManger != undefined){
            this.setState({isEmpManger:true});
        }

        let previous_urls = localStorage.getItem('previousUrls');
        var previousUrls = JSON.parse(previous_urls)
        console.log(previous_urls);
        
        previousUrls.push({'task':window.location.href});
        console.log(previousUrls);
        localStorage.setItem('previousUrls', JSON.stringify(previousUrls));

        var firstUrl = previousUrls[0];
        let backUrl = "";
        if(firstUrl.jobTitles != undefined){
            backUrl = firstUrl.jobTitles;
        
        }else if(firstUrl.stats != undefined){
            backUrl = firstUrl.stats;
        }else{
            backUrl = firstUrl.employeeStats;
        }

        this.setState({prevoiusUrl: backUrl})
       
    }

    handleApproved = async() => {
        let jobId = this.props.match.params.id;
        const response = await approveAllTasks(jobId);
        console.log(response);
        if(response.status == true){
            toast.success(response.message);
            const jobTitles = await getTasks(jobId);
            this.setState({jobData: jobTitles.data});
        }else{
            toast.error(response.message);
        }
    }

    render(){
        const jobData = this.state.jobData;
        let subTasks = jobData.subtasks;
        
        let title = this.state.isEmpManger == true ? 'Employee Tasks' : 'Tasks';
        let sectionClass = this.state.isEmpManger == true ? 'employee-tasks' : 'task';
        let progressStyle = {
            '--value':jobData.progress
        }
        return(
            <>
            <Header title={title} isBack={true} url={this.state.prevoiusUrl}/>
            <section className={sectionClass}>
            {subTasks && 
                <div className="container">
                    <div className="text-cont position-relative">
                        <p>{jobData.shift}</p>
                        <h2>{jobData.title}</h2>
                        <span>{jobData.job_type}</span>

                        <div role="progressbar-round" aria-valuemax="100" style={progressStyle}>
                            <span className="task-number">{jobData.sub_task_count}</span>
                            <span className="progress-text">Tasks</span>
                        </div>
                    </div>

                    <div className=" Periodic">
                        <div className="box">
                            <div className="item">
                                <SubTasks subtasks={subTasks} isEmpManger={this.state.isEmpManger}/> 
                            </div>
                        </div>
                    </div>
                    {this.state.isEmpManger == true && 
                        <div className="approved-btn">
                            <button type="button" className="btn btn-success" onClick={this.handleApproved}>Approved</button>
                        </div>
                    }
                </div>
            }
            </section>
            <Footer />
            </>
        );
    }
}

function SubTasks(props){
    const subtasks = props.subtasks;
    const isEmp = props.isEmpManger;
    let url = "subtasks";
    if(isEmp == true){
        url = "edit-tasks"
    }
    
    let html = "";
    let class_name = "";
    subtasks.map((subtask, index) => {
        let borders = "";
        for(let k=1; k<=subtask.sub_task_count; k++){
            if(subtask.sub_tasks[k-1] != undefined){
                if(subtask.sub_tasks[k-1].completed_at != null){
                    borders+='<span className="border"></span>';
                }else{
                    borders+='<span className="border-grey"></span>';
                }
            }            
        }
        class_name = (index == 0 || index % 2 == 0) ? 'left' : 'right';
        html += (index == 0 || index % 2 == 0) ? '<div className="row">' : '';
        html += 
        `<div className="col-md-6 col-sm-6">
            <div className="card ${class_name}">
                <div className="card-body">
                    <div className="row">
                        <div className="col-10 ">
                            <h3>${subtask.task}</h3>
                            <p>${subtask.sub_task_count} Standards in this task</p>
                            <div class="d-flex">${borders}</div>
                        </div>
                        <div className="col-2 p-0 ">
                            <a href="/${url}/${subtask.id}" className="btn btn-danger">View</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>` ;
        html += (index == 0 || index % 2 == 0) ? '' : '</div>';
    },class_name);
    return( parse(html) );
}

export default Task;