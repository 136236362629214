import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import SubTaskCard from './common/subTaskCard';
import { getTasks } from '../services/jobService';
import parse from 'html-react-parser';

class SubTask extends Component{
    constructor(props){
        super(props);
    }

    state = {
        jobData:{},
        prevoiusUrl: ""
    }

    async componentDidMount(){
        let jobId = this.props.match.params.id;
        const jobTitles = await getTasks(jobId);
        console.log(jobTitles);
        this.setState({jobData: jobTitles.data})
       
        let previous_urls = localStorage.getItem('previousUrls');
        var previousUrls = JSON.parse(previous_urls)
        console.log(previousUrls);
        this.setState({prevoiusUrl: previousUrls[1].task})
    }

    goBack = () =>{
        let previous_urls = localStorage.getItem('previousUrls');
        var previousUrls = JSON.parse(previous_urls);
        previousUrls.pop(previousUrls.lenth-1);
        localStorage.setItem('previousUrls', JSON.stringify(previousUrls));
        window.location.href = this.state.prevoiusUrl;
    }

    render(){
        const jobData = this.state.jobData;
        const subtasks = jobData.subtasks;
        let progressStyle = {
            '--value':jobData.progress
        }
        return(
            <>
                <Header title={'Sub Tasks'} isBack={true} url={this.state.prevoiusUrl}/>
                <section className="sub-task">
                {subtasks &&
                    <div className="container">
                        <div className="text-cont position-relative">
                            <p>{jobData.parent_title}</p>
                            <h2>{jobData.title}</h2>
                            <div role="progressbar-round" aria-valuemax="100" aria-valuenow="0" aria-valuemin="0" style={progressStyle}>
                                <span className="task-number">{jobData.sub_task_count}</span>
                                <span className="progress-text">Standards</span>
                            </div>
                        </div>
                        <div className="row">
                             <TaskCards subtasks={subtasks}/>
                        </div>
                      <div className="approved-btn">
                            <button type="button" className="btn btn-success" onClick={this.goBack}>Back</button>
                        </div>
                    </div>
                }
                </section>
                <Footer />
            </>
        );
    }
}

function TaskCards(props){
    let subtasks = props.subtasks;
    let cards = [];
    
    subtasks.map((task, index) => {
        if(task.completed_at == null)
            cards.push(<div className="col-md-12"><SubTaskCard index={index+1} id={task.id} task={task.task}/></div>);
    });
    return(cards)
}

export default SubTask;