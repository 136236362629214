import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import JobElement from './common/jobElement';
import {getJobTitles} from '../services/jobService';
import parse from 'html-react-parser';

class JobTitle extends JobElement{
    state = {
        jobTitles: {}
    }

    async componentDidMount(){
        const jobTitles = await getJobTitles();
        this.setState({jobTitles: jobTitles.data});
        const previousUrls = [
            {'jobTitles' : window.location.href}
        ];
        localStorage.setItem('previousUrls', JSON.stringify(previousUrls));
    }

    render(){
        const data = this.state.jobTitles;
        
        return(
            <>
            <Header title={'Job Titles'}/>
            { data && 
                <section className="job">
                    <div className="container">
                        <div className="text-cont">
                            <p>{data.shift}</p>
                            <h2>{data.day}</h2>
                            <span>{data.role}</span>
                        </div>
                        <div className=" dog-handler">
                            <div className="box">
                                <div className="item">
                                    <div className="row">
                                    {data && <Item jobTitles={data.job_titles}  />}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="box">
                                <div className="item">
                                    <div className="row">
                                        {this.renderJobElement("Opening Cleaning", "Opening", "7:00", "01 hr", 11) }
                                        {this.renderJobElement("Opening Cleaning", "Cleaning", "7:00", "01 hr", 11) }
                                    </div>
                                    <div className="row">
                                        {this.renderJobElement("Opening Cleaning", "Opening", "7:00", "01 hr", 11) }
                                        {this.renderJobElement("Opening Cleaning", "Cleaning", "7:00", "01 hr", 11) }
                                    </div>
                                    <div className="row">
                                        {this.renderJobElement("Opening Cleaning", "Opening", "7:00", "01 hr", 11) }
                                        {this.renderJobElement("Opening Cleaning", "Cleaning", "7:00", "01 hr", 11) }
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </section>
            }
            <Footer />
            </>
        )
    }
}

function Item(props){
    const jobTitles = props.jobTitles;
    const items = [];
    if(jobTitles!= undefined && jobTitles.length > 0){
        jobTitles.map((obj, index)=>{
            items.push(
            <JobElement title={obj.title} jobType={obj.job_type} startTime={obj.start_time}
            avgDuration={obj.avg_duration} count={obj.count} index={index} id={obj.id} isCompleted={obj.is_completed}/>)
        });
        return (items);
    }else{
        return(
        <div className="col-md-12 col-sm-12">
            <div className="card left">
                <div className="card-body">
                    <div className="no-data">
                        <p>No Record Found</p>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    
}

export default JobTitle;