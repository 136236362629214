import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Footer from './Footer';
import Header from './Header';
import {getTasks} from '../services/jobService';
import {editAllTasks} from '../services/managerServices';

class EditTask extends Component{
    constructor(props){
        super(props);
    }
    state = {
        jobData:{},
        prevoiusUrl: ""
    }

    async componentDidMount(){
        let jobId = this.props.match.params.id;
        const jobTitles = await getTasks(jobId);
        this.setState({jobData: jobTitles.data});

        let previous_urls = localStorage.getItem('previousUrls');
        var previousUrls = JSON.parse(previous_urls)
        this.setState({prevoiusUrl: previousUrls[1].task})
    }

    handleSave = async(e) =>{
        var data = [];
        var elements = document.getElementsByClassName('question');
        let length = elements.length;
        
        for(var i=0; i<= length-1; i++){
            let elemData = {
                id: elements[i].getAttribute('id'),
                behalfOf: elements[i].querySelector('input.behalf_of').value,
                feedback: elements[i].querySelector('textarea.form-control.feedback').value,
                status: elements[i].querySelector('div.select-list').children[0].value
            }
            data.push(elemData);
        }
        
        const response = await editAllTasks(data);

        if(response.status == true){
            toast.success(response.message);
        }else{
            toast.error(response.message);
        }
    }

    render(){
        const jobData = this.state.jobData;
        let progressStyle = {
            '--value':jobData.progress
        }
        return(
            <>
            <Header title={'Edit Tasks'} isBack={true} url={this.state.prevoiusUrl}/>
            <section className="edit-tasks">
                {jobData.subtasks && 
                    <div className="container">
                        <div className="text-cont position-relative">
                            <p className="text-capitalize">{jobData.parent_title}</p>
                            <h2>{jobData.title}</h2>
                            <div role="progressbar-round" aria-valuemax="100" style={progressStyle}>
                                <span className="task-number">{jobData.sub_task_count}</span>
                                <span className="progress-text">Standards</span>
                            </div>
                        </div>

                        <div className="box">
                            <div className="row">
                                <TaskCards subtasks={jobData.subtasks}/>
                            </div>
                        </div>

                        <div className="approved-btn">
                            <button type="button" onClick={(e) => this.handleSave()} className="btn btn-success">Save Changes</button>
                        </div>
                    </div>
                 }
            </section>
            <Footer />
            </>
        )
    }
}

function TaskCards(props){
    let cards = [];
    props.subtasks.map((obj, index) => {
        cards.push(
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body question" id={obj.id}>
                        <h4>QUESTION {index+1}</h4>
                        <h3>{obj.task}</h3>
                        <div className="select-list">
                            <select className="form-select status" aria-label="Yes">
                                <option>Choose</option>
                                <option value="1" selected={(obj.status == 1) ? true : false}>YES</option>
                                <option value="0" selected={(obj.status == 0) ? true : false}>No</option>
                            </select>
                        </div>
                        <h3>Someone else did it for me</h3>
                        <input type="text" className="behalf_of" placeholder="Name" value={obj.someone_else_name}/>
                        <h3>Your Feedback</h3>
                        <textarea className="form-control feedback"  rows="2" placeholder="Enter your feedback">{obj.verifier_feedback}</textarea>
                    </div>
                </div>
            </div>
        );
    });

    return cards;
}

export default EditTask;