import React, { Component } from 'react';
import CalenderTabContent from "./calenderTabContent";
import CalenderTabItem from "./calenderTabItem";

class CalenderTab extends Component{
    constructor(props){
        super(props);
    }
    state = {
        calenderData: this.props.calenderData,
        weekDays: this.props.weekDays
    }
   
    render(){
        const calenderData = this.props.calenderData;
        const weekDays = this.state.weekDays;
        return (
            <div className="responsive-tabs">
                <ul className="nav nav-tabs" role="tablist">
                {this.state.weekDays && <CalenderTabItem weekDays={this.state.weekDays} tabData={calenderData}/> }
                </ul>
                <div id="content1" className="tab-content" role="tablist">
                        <CalenderTabContent weekDays={this.state.weekDays} tabData={calenderData}/> 
                </div>
            </div>
        );
    }
}

export default CalenderTab;