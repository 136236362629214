import React from "react";
import logo from '../assets/img/bark-logo.png';
import LoginForm from "./Forms/LoginForm";

class Login extends React.Component{
    
    state = {
        erros: {},
        data: { email: "", password: "" },
        redirect: true,
        ShowLoginForm: false,
        Loading: false,
        serverErrorMsg: ""
    }
    
    render(){
        return (
            <section className="login-panel">
                <div className="container">
                    <div className="foam">
                        <div className="img-cont">
                            <img src={logo} className="img-fluid" alt="logo" />
                        </div>
                        <LoginForm />
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;