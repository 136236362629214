import http from './httpService';
import Cookies from 'js-cookie';
import { ToastsStore } from 'react-toasts';

const apiEndpoint = http.apiEndpoint;
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
    const {data} = await http.post(apiEndpoint+ "/auth/login", { email, password });
    if(data.status === false){
        ToastsStore.error(data.message);
    }else{
        localStorage.setItem(tokenKey,  data.data.api_token);
        localStorage.setItem('user',  JSON.stringify(data.data));
        Cookies.set('accessToken', data.data.api_token)
    }
    return data;
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}
  
export function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('user');
    window.location = '/';
}
  
export function getCurrentUser() {
    try {
      const user = localStorage.getItem('user');
      return user;
    } catch (ex) {
      return null;
    }
}

export function getJwt(){
    return localStorage.getItem(tokenKey);
}

export function forgotPassword(email){
    return http.post(apiEndpoint+'/auth/forgot-password', 
        {email: email}
    ).then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function verifyOtp(otp, token){
    return http.post(apiEndpoint+'/auth/verify-token', 
        {otp_token: otp, api_token: token}
    ).then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function resendOtp(token){
    return http.post(apiEndpoint+'/auth/resend-otp-token', 
        {api_token: token}
    ).then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function resetPassword(password, token){
    return http.post(apiEndpoint+'/auth/reset-password', 
        {password: password, api_token: token}
    ).then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    forgotPassword,
    getJwt
};
