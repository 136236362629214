import EmployeeStatsTabContent from "./employeeStatsContent";

export default function EmployeeStatsTab(props){
    const weekDays = props.weekDays;

    return(
        <div className=" responsive-tabs">
            <ul className="nav nav-tabs" role="tablist">
                {props.calenderData && <EmployeeStatsTabItem weekDays={weekDays}/>}
            </ul>
            <div id="content-1" className="tab-content" role="tablist">
                {props.calenderData && <EmployeeStatsTabContent weekDays={weekDays} tabData={props.calenderData}/>}
            </div>
        </div>

    );
    
}

function EmployeeStatsTabItem (props){
    const weekDays = props.weekDays;
    const items = [];
    var selectedEmp = JSON.parse(localStorage.getItem('emp_active_box'));
    weekDays.map((weekDay, index)=>{
        let activeClass = '';
        let showClass = '';
        
        if(selectedEmp != undefined){
            if(selectedEmp.tab != undefined && selectedEmp.tab == 'tab-'+index){
                activeClass = 'active';
                showClass = 'show'
            }
        }else{
            if(weekDay.current_day == true){   
                activeClass = 'active';
                showClass = 'show'
            }
        }
        
        items.push(
            <li className="nav-item empStatTab" data-tcontent={index}>
                <a id={"tab-"+index} href={"#pos-"+index} title={weekDay.date} className={"nav-link "+activeClass} data-bs-toggle="tab" role="tab">
                    <div className="number">{weekDay.day}</div>
                    <div className="day">{weekDay.week_day}</div>
                </a>
            </li>
        )
    });
     
    return (items);
}