import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "../Forms/loginInput";


class Form extends Component{
    state = {
        data: {},
        errors: {},
        errMsg: null
    };

    validate = () => {
        const { error } = Joi.validate(this.state.data, this.schema, {abortEarly: false});
        if(!error) return null;
 
        const errors = {};
        const { classNames } = this.state;
        for(let item of error.details){
            errors[item.path[0]] = item.message;
            classNames[item.path[0]] += " is-invalid";
        }
 
        return errors;    
    }
 
    validateProperty = ({name, value}) => {
        const obj = { [name] : value };
        const schema = { [name] : this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null
    }

    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        //this.setState({ errMsg: Object.values(errors).join('\r\n') || null });
        console.log(errors);
        if(errors) return;
        else this.doSubmit();            
    }

    handleChange = ({ currentTarget:input }) => {
        const errors = { ...this.state.errors };
        const erroMessage = this.validateProperty(input);
        
        if(erroMessage) errors[input.name] = erroMessage;
        else delete errors[input.name];

        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({ data, errors });
    }

    renderButton(label, className){
        return (
            <button className={className}>{label}</button>
        );
    }

    renderInput(name, placeholder, type='text'){
        const { data } = this.state;
        return (
            <Input type={type} name={name} 
                value={data[name]} 
                placeholder={placeholder}
                onChange={this.handleChange} />
        );
    }
}

export default Form;