import React, { Component } from 'react';
import parse from 'html-react-parser';
class JobElement extends Component{
    constructor(props){
        super(props);
    }
    render(){
        const data = this.props;
        let completedClass = data.isCompleted == 0 ? '' : 'completed';
        let html = "";
        //html += (data.index == 0 || data.index % 2 == 0) ? '<div className="row">' : '';
        html +=  `<div className="col-md-6 col-sm-6">
                <div className="card left">
                    <div className="card-body ${completedClass}">
                        <div className="row">
                            <div className="col-9">
                                <h4 class="active">
                                    <a href="/tasks/${data.id}">${data.title}</a>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13Z" fill="#979797"/>
                                        <path d="M8.27271 12.0546L11.657 15.3637L17.7273 9.45459" stroke="#FCFCFC" strokeWidth="2"/>
                                    </svg>
                                </h4>

                                <div className="d-flex">
                                    <span className="job-type">Job Type</span>
                                    <span className="Periodic">${data.jobType != null ? data.jobType : ''}</span>
                                </div>
                                <div className="d-flex">
                                    <span className="start-time">Start Time</span>
                                    <span className="nmbr">${data.startTime != null ? data.startTime : ''}</span>
                                </div>
                                <div className="d-flex">
                                    <span className="duration">Avg Duration</span>
                                    <span className="hour">${data.avgDuration != null ? data.avgDuration : ''}</span>
                                </div>
                            </div>
                            <div className="col-3 p-0">
                                <h4>TASKS</h4>
                                <div className="number">
                                    <span className="vv">${data.count}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`

           //html += (data.index == 0 || data.index % 2 == 0) ? '' : '</div>';
        return(parse(html));
    }
}

export default JobElement;