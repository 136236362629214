import parse from 'html-react-parser';

export default function StatsTabContent(props){
    const weekDays = props.weekDays;
    const tabData = props.tabData;
    let listContents = "";
    console.log(tabData);

    if(tabData.length > 0){
        weekDays.map((weekDay, index)=>{
            let activeClass = '';
            let showClass = '';
            var shift = (tabData[index] != null && tabData[index] != undefined) ? tabData[index].shift: undefined;
            var jobs = (tabData[index] != null && tabData[index] != undefined) ? tabData[index].jobs : undefined;
            var jobTitles = "";
            if(weekDay.current_day == true){   
                activeClass = 'active';
                showClass = 'show'
            }
            if(jobs != undefined){
                if(jobs.employee_parent_job_tasks != undefined){
                    jobs.employee_parent_job_tasks.map((obj)=>{
                        let statusColor = (obj.completed_at != null) ? '#29B86D' : '#979797';
                        jobTitles += `<li>
                            <div className="d-flex">
                                <div className="left">
                                    <a href="/tasks/${obj.id}">${obj.task}</a>
                                </div>
                                <div className="right">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9" cy="9" r="9" fill="${statusColor}"/>
                                        <path d="M5.72754 8.34541L8.07051 10.6363L12.273 6.54541" stroke="#FCFCFC"/>
                                    </svg>
                                </div>
                            </div>
                        </li>`
                    });
                }
                listContents+=  
                `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel" aria-labelledby="tab-${index}">
                    <div className="card-header" role="tab" id="heading-${index}">
                        <h5 className="mb-0">
                            <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true" aria-controls="collapse-${index}">
                                <div className="number">${weekDay.day}</div>
                                <div className="day">${weekDay.week_day}</div>
                            </a>
                        </h5>
    
                    </div>
                    <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content" role="tabpanel"
                        aria-labelledby="heading-${index}">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2 pr-0">
                                    <div className="box">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <!--div className="col-lg-8 col-md-12 col-sm-6 pr-0">
                                                        <div className="d-flex">
                                                            <span className="shift">SHIFT</span>
                                                            <span className="first-shift">${shift.name}</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="time">TIMINGS</span>
                                                            <span className="pm">${shift.start_time + ' - ' + shift.end_time}</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="category">CATEGORY</span>
                                                            <span className="handler">${shift.category}</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="type">JOB TYPE</span>
                                                            <span className="periodic">${shift.job_type}</span>
                                                        </div>
                                                    </div-->
                                                    <div className="col-lg-12 col-md-12 text-center col-sm-6">
                                                        <div className="circle m-auto">
                                                            ${shift.completed_percent}
                                                            <p>Percent</p>
                                                        </div>
                                                        <span className="achive">Bonus Achived</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div className="col-md-10">
                                    <div className="text-content">
                                        <h3>Task Details</h3>
                                        <ul className="detail-list">
                                            ${jobTitles}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`
            }else{
                listContents += `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel"
                aria-labelledby="tab-${index}">
                <div className="card-header" role="tab" id="heading-${index}">
                    <h5 className="mb-0">
                        <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true" 
                        aria-controls="collapse-${index}">
                            <div className="number">${weekDay.day}</div>
                            <div className="day">${weekDay.week_day}</div>
                        </a>
                    </h5>
                </div>
                <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content"
                    role="tabpanel"
                    aria-labelledby="heading-${index}">
                    <div className="card-body">
                        <div className="no-data">
                            <p>No Record Found</p>
                        </div>
                    </div>
                </div>
                </div>`
            }               
        });

        return (parse(listContents));
    } else{
        return ("No Record Found");
    }
}