import parse from 'html-react-parser';
function CalenderTabContent(props){
    const weekDays = props.weekDays;
    const tabData = props.tabData;
    let listContents = "";
    if(tabData.length > 0){
        weekDays.map((weekDay, index)=>{
            let activeClass = '';
            let showClass = '';
            var shift = (tabData[index] != null && tabData[index] != undefined) ? tabData[index].shift: undefined;
            var jobs = (tabData[index] != null && tabData[index] != undefined) ? tabData[index].jobs : undefined;
            var jobTitles = "";
            if(weekDay.current_day == true){   
                activeClass = 'active';
                showClass = 'show'
            }
            if(jobs != undefined){
                if(jobs.employee_parent_job_tasks != undefined){
                    let totalLength = jobs.employee_parent_job_tasks.length;
                    let firstLength = Math.ceil(totalLength/2);
                    
                    for(let i=0; i<= firstLength-1; i++){
                        var obj = jobs.employee_parent_job_tasks[i];
                        if(i == 0){
                            jobTitles += '<div className="col-lg-6 col-md-6 col-sm-6"><ul className="left pt-3">';
                        }
                        jobTitles += '<li><a href="/tasks/'+obj.id+'">'+obj.task+'</a></li>';
                          
                        if(i == firstLength-1){
                            jobTitles += '</ul></div>';
                        }
                    }
                    for(let i=firstLength; i<= totalLength-1; i++){
                        var obj = jobs.employee_parent_job_tasks[i];
                        if(i == firstLength){
                            jobTitles += '<div className="col-lg-6 col-md-6 col-sm-6"><ul className="right pt-3">';
                        }
                        jobTitles += '<li><a href="/tasks/'+obj.id+'">'+obj.task+'</a></li>';
                          
                        if(i == totalLength-1){
                            jobTitles += '</ul></div>';
                        }
                    }
                    
                    // jobs.employee_parent_job_tasks.map((obj)=>{
                    //     jobTitles += '<li><a href="/tasks/'+obj.id+'">'+obj.task+'</a></li>';
                    // });
                }
                listContents+=   
                `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel"
                    aria-labelledby="tab-${index}">
                    <div className="card-header" role="tab" id="heading-${index}">
                        <h5 className="mb-0">
                            <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true"
                            aria-controls="collapse-${index}">
                                ${weekDay.week_day}
                            </a>
                        </h5>
                    </div>
                    <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content"
                        role="tabpanel"
                        aria-labelledby="heading-${index}">
                        <div className="card-body">
                            <div className="card-body-inner">
                                <div className="shift-detail">
                                    <div className="text-content">
                                        <h3>Shift Details</h3>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                            <p className="shift">Shift</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 p-0">
                                            <p className="first-shift">${shift.name}</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 offset-4 col-6">
                                            <p className="timing">Shift Hours</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 p-0">
                                            <p className="pm">${shift.start_time + ' - ' + shift.end_time}</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                            <p className="category">Position</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 p-0">
                                            <p className="handler">${shift.category}</p>
                                        </div>
                                        <!--div className="col-lg-2 col-md-2 col-sm-2 offset-4 col-6">
                                            <p className="type">JOB TYPE</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 p-0">
                                            <p className="ongoing">${shift.job_type}</p>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4"/>
                            <div className="card-body-inner">
                                <div className="text-content">
                                    <h3><span className="job-title">JOB TITLES</span></h3>
                                </div>
                                <div className="row">
                                    ${jobTitles}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`
            }else{
                listContents += `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel"
                aria-labelledby="tab-${index}">
                <div className="card-header" role="tab" id="heading-${index}">
                    <h5 className="mb-0">
                        <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true"
                        aria-controls="collapse-${index}">
                            ${weekDay.week_day}
                        </a>
                    </h5>
                </div>
                <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content"
                    role="tabpanel"
                    aria-labelledby="heading-${index}">
                    <div className="card-body">
                        <div className="no-data">
                            <p>No Record Found</p>
                        </div>
                    </div>
                </div>
                </div>`
            }
            
        });

        return (parse(listContents))
    } else{
        return ("No Record Found");
    }
}

export default CalenderTabContent;


/*
<div id={"pos-collapse-"+props.id} className="collapse show" data-bs-parent="#content"
                role="tabpanel"
                aria-labelledby={"heading-"+props.id}>
                <div className="card-body">
                    <div className="card-body-inner">
                        <div className="shift-detail">
                            <div className="text-content">
                                <h3>Shift Details</h3>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="shift">SHIFT</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="first-shift">First Shift</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 offset-4 col-6">
                                    <p className="timing">TIMINGS</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="pm">8:00am - 5:00pm</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="category">CATEGORY</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="handler">Dog Handler</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 offset-4 col-6">
                                    <p className="type">JOB TYPE</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                    <p className="ongoing">Periodic, Ongoing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-4"/>
                        <div className="card-body-inner">
                            <div className="text-content">
                                <h3><span className="job-title">JOB TITLES</span></h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <ul className="left pt-3">
                                        <li>Opening Cleaning</li>
                                        <li>Customer Check-Ins</li>
                                        <li>Play-Groups Supervision</li>
                                        <li>Suite-Stay Dogs</li>
                                        <li>Water Bowls</li>
                                        <li>Mop Buckets</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6  col-md-6 col-sm-6">
                                    <ul className="right pt-3">
                                        <li>Laundry</li>
                                        <li>Customer Check-Outs</li>
                                        <li>Lobby and Restroom</li>
                                        <li>Mid-day Clean, BOARDING Side</li>
                                        <li>Mid-day Clean, DAYCARE Side</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        */