import React, { Component } from 'react';
// import { useLocation } from 'react-router-dom';
import logo from '../assets/img/bark-logo.png';
import { verifyOtp, resendOtp } from '../services/authService';
class OtpToken extends Component{
    constructor(props) {
        super(props);
    }

    state = {
        token: '',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
        otp6: '',
        errMsg: '',
        successMsg: '',
        minutes: 1,
        seconds: 12
    }

    componentDidMount(){
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.setState({'token': params.token})

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    handleChange = (elemKey, { currentTarget:input }) => {
        this.setState({[elemKey]: input.value});
    }

    inputFocus = (elem) => {
        if(elem.key === "Delete" || elem.key === "Backspace"){
            const next = elem.target.tabIndex - 2;
            if(next > -1){
                elem.target.form.elements[next].focus();
            }
        }else{
            const next = elem.target.tabIndex;
            if (next < 6) {
                elem.target.form.elements[next].focus()
            }
        }
    }

    handleSubmit = async(e) => {
        
        const value = this.state.otp1+this.state.otp2+this.state.otp3+this.state.otp4+this.state.otp5+this.state.otp6;
        if(value != ""){
            const response = await verifyOtp(value, this.state.token);
            if(response.status == true){
                this.setState({'successMsg': response.message});
                this.setState({'errMsg': ''});
                window.location = '/reset-password?token='+this.state.token;
            }else{
                this.setState({'successMsg': ''});
                this.setState({'errMsg': response.message});
            }
        }else{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': 'Please enter OTP token'});
        }

        setTimeout(() =>{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': ''});
        }, 10000);
    }

    resendOtpToken = async(e) => {
        const response = await resendOtp(this.state.token);
        
        if(response.status == true){
            this.setState({'successMsg': response.message});
            this.setState({'errMsg': ''});
        }else{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': response.message});
        }
    }

    render(){
        const { minutes, seconds } = this.state;
        let resendClass = {
            cursor:"pointer"
        }
        return(
            <section className="forgot-password">
                <div className="container">
                    <div className="foam">
                        <div className="img-cont">
                            <img src={logo} className="img-fluid" />
                        </div>
                        <div className="text-cont">
                            <h2>Verify OTP Token</h2>
                            <p>Please check your email for OTP Code</p>
                        </div>
                        <div className="user-login">
                            <form>
                            {this.state.errMsg && <div className="alert alert-danger">{this.state.errMsg}</div>}
                            {this.state.successMsg && <div className="alert alert-success">{this.state.successMsg}</div>}

                            <div className="otp-input-fields">
                                <input name="otp1" type="text" className="otp__digit otp__field__1" 
                                    maxLength="1" tabIndex="1" autoComplete='off' value={this.state.otp1}
                                    onChange={e => this.handleChange("otp1", e)} onKeyUp={e => this.inputFocus(e)}/>

                                <input name="otp2" type="text" className="otp__digit otp__field__2"
                                    maxLength="1" tabIndex="2" autoComplete='off' value={this.state.otp2}
                                    onChange={e => this.handleChange("otp2", e)} onKeyUp={e => this.inputFocus(e)}/>

                                <input name="otp3" type="text" className="otp__digit otp__field__3" 
                                    maxLength="1" tabIndex="3" autoComplete='off' value={this.state.otp3}
                                    onChange={e => this.handleChange("otp3", e)} onKeyUp={e => this.inputFocus(e)}/>

                                <input name="otp4" type="text" className="otp__digit otp__field__4" 
                                    maxLength="1" tabIndex="4" autoComplete='off' value={this.state.otp4}
                                    onChange={e => this.handleChange("otp4", e)} onKeyUp={e => this.inputFocus(e)}/>

                                <input name="otp5" type="text" className="otp__digit otp__field__5" 
                                    maxLength="1" tabIndex="5" autoComplete='off' value={this.state.otp5}
                                    onChange={e => this.handleChange("otp5", e)} onKeyUp={e => this.inputFocus(e)}/>

                                <input name="otp6" type="text" className="otp__digit otp__field__6" 
                                maxLength="1" tabIndex="6" autoComplete='off' value={this.state.otp6}
                                onChange={e => this.handleChange("otp6", e)} onKeyUp={e => this.inputFocus(e)}/>
                            </div>

                            <span className="resend-code">
                                <p href="javascript:void(0);" onClick={this.resendOtpToken} style={resendClass}>Resend Code in 
                                 <span> {minutes}:{seconds}</span></p>
                            </span>

                            <div className="btn-login">
                                <button type="button" className="btn btn-danger" onClick={this.handleSubmit}>Submit</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default OtpToken;