import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom'
function Footer(){
    const location = useLocation();
    let pathname = location.pathname;
    let calenderIconClass = "footer-icons";
    let tasksIconClass = "footer-icons";
    let statsIconClass ="footer-icons";
    let profileIconClass = "footer-icons";
    if(pathname == '/calender'){
        calenderIconClass += ' active';
        localStorage.removeItem('emp_active_box');
    }else if(pathname == '/profile'){
        profileIconClass += ' active';
        localStorage.removeItem('emp_active_box');
    }else if(pathname == '/stats' || pathname == '/employee-stats' ){
        statsIconClass += ' active';
    }else{
        tasksIconClass += ' active';
    }

    return (
        <>
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                        <div className={calenderIconClass}>
                            <a href="/calender">  
                                <div className="svg-cont">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M26 5V6H0V5C0 3.346 1.346 2 3 2H4V1C4 0.447 4.448 0 5 0C5.552 0 6 0.447 6 1V2H12V1C12 0.447 12.448 0 13 0C13.552 0 14 0.447 14 1V2H20V1C20 0.447 20.448 0 21 0C21.552 0 22 0.447 22 1V2H23C24.654 2 26 3.346 26 5ZM30 23C30 26.865 26.866 30 23 30C19.134 30 16 26.865 16 23C16 19.135 19.134 16 23 16C26.866 16 30 19.135 30 23ZM27 23C27 22.447 26.552 22 26 22H24V20C24 19.447 23.552 19 23 19C22.448 19 22 19.447 22 20V23C22 23.553 22.448 24 23 24H26C26.552 24 27 23.553 27 23ZM26 8V14.52C25.06 14.18 24.05 14 23 14C18.04 14 14 18.04 14 23C14 24.05 14.18 25.06 14.52 26H3C1.35 26 0 24.65 0 23V8H26ZM9 21C9 20.447 8.552 20 8 20H6C5.448 20 5 20.447 5 21C5 21.553 5.448 22 6 22H8C8.552 22 9 21.553 9 21ZM9 17C9 16.447 8.552 16 8 16H6C5.448 16 5 16.447 5 17C5 17.553 5.448 18 6 18H8C8.552 18 9 17.553 9 17ZM9 13C9 12.447 8.552 12 8 12H6C5.448 12 5 12.447 5 13C5 13.553 5.448 14 6 14H8C8.552 14 9 13.553 9 13ZM15 13C15 12.447 14.552 12 14 12H12C11.448 12 11 12.447 11 13C11 13.553 11.448 14 12 14H14C14.552 14 15 13.553 15 13Z"
                                                fill="#64140F"/>
                                        </svg>
                                </div>
                                <div className="text-content">
                                    <p>Your Week View</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                        <div className={tasksIconClass}>
                            <a href="/job-titles">
                                <div className="svg-cont">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.285C0 26.745 3.255 30 8.715 30H21.285C26.745 30 30 26.745 30 21.285V8.715C30 3.255 26.745 0 21.285 0ZM11.955 19.35L8.58 22.725C8.355 22.95 8.07 23.055 7.785 23.055C7.5 23.055 7.2 22.95 6.99 22.725L5.865 21.6C5.415 21.165 5.415 20.445 5.865 20.01C6.3 19.575 7.005 19.575 7.455 20.01L7.785 20.34L10.365 17.76C10.8 17.325 11.505 17.325 11.955 17.76C12.39 18.195 12.39 18.915 11.955 19.35ZM11.955 8.85L8.58 12.225C8.355 12.45 8.07 12.555 7.785 12.555C7.5 12.555 7.2 12.45 6.99 12.225L5.865 11.1C5.415 10.665 5.415 9.945 5.865 9.51C6.3 9.075 7.005 9.075 7.455 9.51L7.785 9.84L10.365 7.26C10.8 6.825 11.505 6.825 11.955 7.26C12.39 7.695 12.39 8.415 11.955 8.85ZM23.34 21.93H15.465C14.85 21.93 14.34 21.42 14.34 20.805C14.34 20.19 14.85 19.68 15.465 19.68H23.34C23.97 19.68 24.465 20.19 24.465 20.805C24.465 21.42 23.97 21.93 23.34 21.93ZM23.34 11.43H15.465C14.85 11.43 14.34 10.92 14.34 10.305C14.34 9.69 14.85 9.18 15.465 9.18H23.34C23.97 9.18 24.465 9.69 24.465 10.305C24.465 10.92 23.97 11.43 23.34 11.43Z"
                                            fill="#64140F"/>
                                    </svg>

                                </div>
                                <div className="text-content">
                                    <p>Your Job Tasks</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                        <div className={statsIconClass}>
                            <a href="/stats">
                                <div className="svg-cont">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.45312 14.0625C5.1622 14.0625 5.80399 13.778 6.27823 13.3212L8.87993 14.6219C8.86688 14.7279 8.84766 14.832 8.84766 14.9414C8.84766 16.3953 10.0305 17.5781 11.4844 17.5781C12.9382 17.5781 14.1211 16.3953 14.1211 14.9414C14.1211 14.5356 14.0213 14.1557 13.8567 13.8119L17.3861 10.2825C17.7299 10.4471 18.1098 10.5469 18.5156 10.5469C19.9695 10.5469 21.1523 9.36401 21.1523 7.91016C21.1523 7.63641 21.0986 7.37755 21.0207 7.12921L24.0873 4.82986C24.5057 5.10933 25.0072 5.27344 25.5469 5.27344C27.0007 5.27344 28.1836 4.09058 28.1836 2.63672C28.1836 1.18286 27.0007 0 25.5469 0C24.093 0 22.9102 1.18286 22.9102 2.63672C22.9102 2.91046 22.9639 3.16933 23.0418 3.41766L19.9752 5.71701C19.5568 5.43755 19.0553 5.27344 18.5156 5.27344C17.0618 5.27344 15.8789 6.4563 15.8789 7.91016C15.8789 8.31596 15.9787 8.69591 16.1433 9.03969L12.6139 12.569C12.2701 12.4045 11.8902 12.3047 11.4844 12.3047C10.7753 12.3047 10.1335 12.5892 9.65927 13.046L7.05757 11.7453C7.07062 11.6393 7.08984 11.5352 7.08984 11.4258C7.08984 9.97192 5.90698 8.78906 4.45312 8.78906C2.99927 8.78906 1.81641 9.97192 1.81641 11.4258C1.81641 12.8796 2.99927 14.0625 4.45312 14.0625Z"
                                            fill="#64140F"/>
                                        <path
                                            d="M29.1211 28.2422H28.1836V9.66797C28.1836 9.18228 27.7904 8.78906 27.3047 8.78906H23.7891C23.3034 8.78906 22.9102 9.18228 22.9102 9.66797V28.2422H21.1523V14.9414C21.1523 14.4557 20.7591 14.0625 20.2734 14.0625H16.7578C16.2721 14.0625 15.8789 14.4557 15.8789 14.9414V28.2422H14.1211V21.9727C14.1211 21.487 13.7279 21.0938 13.2422 21.0938H9.72656C9.24088 21.0938 8.84766 21.487 8.84766 21.9727V28.2422H7.08984V18.457C7.08984 17.9713 6.69662 17.5781 6.21094 17.5781H2.69531C2.20963 17.5781 1.81641 17.9713 1.81641 18.457V28.2422H0.878906C0.393219 28.2422 0 28.6354 0 29.1211C0 29.6068 0.393219 30 0.878906 30H29.1211C29.6068 30 30 29.6068 30 29.1211C30 28.6354 29.6068 28.2422 29.1211 28.2422Z"
                                            fill="#64140F"/>
                                    </svg>
                                </div>
                                <div className="text-content">
                                    <p>Performance Review</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                        <div className={profileIconClass}>
                            <a href="/profile">
                                <div className="svg-cont">
                                    <svg width="26" height="30" viewBox="0 0 26 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.8032 14.451C14.8655 14.451 16.6513 13.739 18.1105 12.3341C19.5696 10.9295 20.3093 9.21083 20.3093 7.2253C20.3093 5.24044 19.5696 3.52155 18.1102 2.11646C16.6509 0.712047 14.8653 0 12.8032 0C10.7406 0 8.95529 0.712047 7.49616 2.11669C6.03703 3.52132 5.29712 5.24022 5.29712 7.2253C5.29712 9.21083 6.03703 10.9297 7.4964 12.3344C8.95577 13.7388 10.7413 14.451 12.8032 14.451Z"
                                            fill="#64140F"/>
                                        <path
                                            d="M25.937 23.0685C25.8949 22.4839 25.8098 21.8463 25.6845 21.1729C25.558 20.4945 25.3951 19.8532 25.2002 19.267C24.9988 18.6612 24.7249 18.0629 24.3863 17.4895C24.0349 16.8945 23.6222 16.3763 23.159 15.9499C22.6747 15.5038 22.0817 15.1451 21.396 14.8835C20.7127 14.6233 19.9554 14.4914 19.1454 14.4914C18.8273 14.4914 18.5196 14.6171 17.9254 14.9895C17.5598 15.2191 17.132 15.4846 16.6546 15.7782C16.2464 16.0286 15.6933 16.2632 15.0103 16.4756C14.3438 16.6832 13.6672 16.7885 12.9993 16.7885C12.3314 16.7885 11.655 16.6832 10.9878 16.4756C10.3055 16.2634 9.75244 16.0288 9.34468 15.7784C8.87177 15.4875 8.44381 15.222 8.07266 14.9893C7.47921 14.6169 7.17132 14.4912 6.85319 14.4912C6.04291 14.4912 5.28588 14.6233 4.60279 14.8837C3.91757 15.1449 3.32436 15.5035 2.83957 15.9501C2.37665 16.3767 1.96366 16.8947 1.61273 17.4895C1.27439 18.0629 1.00049 18.661 0.798873 19.2673C0.604148 19.8534 0.441282 20.4945 0.314794 21.1729C0.189495 21.8454 0.104377 22.4833 0.0622931 23.0692C0.0209229 23.6432 0 24.239 0 24.8407C0 26.4067 0.517128 27.6745 1.53688 28.6095C2.54403 29.5321 3.87668 30.0001 5.49725 30.0001H20.5028C22.1233 30.0001 23.4555 29.5323 24.4629 28.6095C25.4829 27.6752 26 26.4072 26 24.8405C25.9998 24.236 25.9786 23.6398 25.937 23.0685Z"
                                            fill="#64140F"/>
                                    </svg>

                                </div>
                                <div className="text-content">
                                    <p>Profile</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <ToastContainer />
        </>
    );
}

export default Footer;