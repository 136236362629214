import Joi from "joi-browser";
import React, {Component} from "react";
import auth, { login } from "../../services/authService";
import Form from "../common/form";

class LoginForm extends Form{

    state = {
        data : { email: '', password: ''},
        errors : {},
        classNames: {email: 'input-group flex-nowrap', password: 'input-group flex-nowrap'},
        loading: false,
        errMsg: null
    };
    schema = {
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().label("Password")
    };

    doSubmit = async () => {
        // Call the server
        try {
            this.setState({loading: true})
            const { data } = this.state;
            const response = await auth.login(data.email, data.password)
            this.setState({loading: false})
            if(response.status === true){
                window.location = "/calender";
            }else{
                let errMsg = response.message;
                this.setState({errMsg, errMsg});
            }
        } catch (ex) {
            if (ex.response && (ex.response.status === false)) {
                const errors = { ...this.state.errors };
                let res = ex.response.data.data
                console.log('errors',res)
                res.forEach(element => {
                    errors[element.field] = element.message;
                });
                // this.state.serverErrorMessage = ex.response.data.message
                this.setState({ errors,loading:false });
            }
        }
        
        console.log("Submitted"); 
        setTimeout(() =>{
            this.setState({'errMsg': ''});
        }, 10000);
    }

    render(){
        const { errors, classNames } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="text-cont">
                    <h2>Login</h2>
                    <p>Please enter your username and password to login</p>
                </div>
                <div className="user-login">
                    {this.state.errMsg && <div className="alert alert-danger">{this.state.errMsg}</div>}
                    <div className={classNames.email}>
                        <span className="input-group-text" id="addon-wrapping">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 14.75V13.25C13 12.4544 12.6839 11.6913 12.1213 11.1287C11.5587 10.5661 10.7956 10.25 10 10.25H4C3.20435 10.25 2.44129 10.5661 1.87868 11.1287C1.31607 11.6913 1 12.4544 1 13.25V14.75"
                                stroke="#EE3124"/>
                            <path
                                d="M7 7.25C8.65685 7.25 10 5.90685 10 4.25C10 2.59315 8.65685 1.25 7 1.25C5.34315 1.25 4 2.59315 4 4.25C4 5.90685 5.34315 7.25 7 7.25Z"
                                stroke="#EE3124"/>
                            </svg>
                        </span>
                        {this.renderInput('email', 'Email', 'email')}
                    </div>
                    {errors.email && <span className="invalid-feedback" role="alert"><strong>{errors.email}</strong></span> }
                    
                    <div className={classNames.password}>
                        <span className="input-group-text" id="addon">
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.2 7.2998H2.4C1.6268 7.2998 1 7.92661 1 8.6998V13.5998C1 14.373 1.6268 14.9998 2.4 14.9998H12.2C12.9732 14.9998 13.6 14.373 13.6 13.5998V8.6998C13.6 7.92661 12.9732 7.2998 12.2 7.2998Z"
                                stroke="#EE3124"/>
                            <path
                                d="M3.79999 7.3V4.5C3.79999 3.57174 4.16874 2.6815 4.82511 2.02513C5.48149 1.36875 6.37173 1 7.29999 1C8.22825 1 9.11848 1.36875 9.77486 2.02513C10.4312 2.6815 10.8 3.57174 10.8 4.5V7.3"
                                stroke="#EE3124"/>
                            </svg>
                        </span>
                        {this.renderInput('password', 'Password', 'password')}
                    </div>
                    {errors.password && <span className="invalid-feedback" role="alert"><strong>{errors.password}</strong></span> }
                    
                    <span className="forgot-pass">
                        <a href="/forgot-password">Forgot Password?</a>
                    </span>
                    <div className="btn-login">
                        {this.renderButton('Login', 'btn btn-danger')}
                    </div>
                </div>
            </form>
        );
    }
}

export default LoginForm;