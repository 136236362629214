import React from "react";

const Input = ({name, value, placeholder, onChange, type='text'}) => {
    return (
    
        <input type={type}
        name={name} 
        id={name}  
        className="form-control" 
        placeholder={placeholder} 
        aria-label={placeholder}
        aria-describedby="addon-wrapping" autoFocus
        value={value}
        onChange={onChange}/>        
    )
}

export default Input;