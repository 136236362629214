import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedRoute = ({ path, component: Component, ...rest }) => {
    
    return (
        <Route 
            {...rest}
            render={(props) =>
                (auth.getCurrentUser() != null) ? <Component {...props}/> :  <Redirect to="/" />
            }
        />
    );
}

export default ProtectedRoute;