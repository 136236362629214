import React from "react";
import Header from "./Header";
import Footer from "./Footer";
//import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import CalenderTab from "./common/calenderTab";
import {getWeekCalendar, getCurrentWeekDays} from "../services/jobService";

class Calender extends React.Component{

    constructor(props) {
        super(props);
    }
    state = {
        calenderData: {},
        weekDays: {},
        days: 0,
    }

    async componentDidMount(){  
        let weekData = await getCurrentWeekDays(0);
        this.setState({weekDays: weekData})

        let calenderData = await getWeekCalendar(0);
        this.setState({calenderData:calenderData});
        
    }

    next = async(e) => {
        let days = this.state.days;

        let weekData = await getCurrentWeekDays(days+7);
        this.setState({weekDays: weekData})

        let calenderData = await getWeekCalendar(days+7);
        this.setState({calenderData:calenderData});
        this.setState({days:days+7});
    }
    previous = async(e) => {
        let days = this.state.days;

        let weekData = await getCurrentWeekDays(days-7);
        this.setState({weekDays: weekData})

        let calenderData = await getWeekCalendar(days-7);
        this.setState({calenderData:calenderData});
        this.setState({days:days-7});
    }

    render() {
        
        return (
            <>
            <Header title={'Calender'} />
            <section className="calender">
                <div className="container">
                {this.state.calenderData.data &&
                    
                    <div className="owl-carousal week-calender">
                        <div className="item">
                            <div className="d-flex justify-content-center mt-4 position-relative">
                                <div className="text-cont">
                                    <div className="owl-nav">
                                        <button type="button" role="presentation" className="owl-prev carousalBtn" onClick={(e) => this.previous()}>
                                            <span aria-label="Previous">‹</span>
                                        </button>
                                        <button type="button" role="presentation" className="owl-next carousalBtn" onClick={(e) => this.next()}>
                                            <span aria-label="Next">›</span>
                                        </button>
                                    </div>
                                    <h2>WEEK</h2>
                                    <p>{this.state.weekDays.data && this.state.weekDays.data.weekName }</p>
                                </div>
                            </div>
                            <CalenderTab calenderData={this.state.calenderData.data} weekDays={this.state.weekDays.data.weekDays}/>
                        </div>    
                    </div>           
                   
                }
                </div>
            </section>
            <Footer />
            </>
        );
    }
}

 export default Calender;