import parse from 'html-react-parser';

export default function CalenderTabItem (props){
    const weekDays = props.weekDays;
    let listItems = "";
    weekDays.map((weekDay, index)=>{
        let activeClass = '';
        let showClass = '';
        if(weekDay.current_day == true){   
            activeClass = 'active';
            showClass = 'show'
        }
        listItems += `<li className="nav-item position-relative">
        <a id="tab-${index}" href="#pos-${index}" title=${weekDay.date} className="nav-link ${activeClass}" data-bs-toggle="tab" role="tab">
        ${weekDay.week_day} ${!weekDay.off_day ? '<span className="dot">.</span>': ''}</a></li>`
    });
     
    return (parse(listItems));
}