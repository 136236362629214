import React, { Component } from 'react';
import logo from '../assets/img/bark-logo.png';
import { resetPassword } from '../services/authService';
class ResetPassword extends Component{
    state = {
        password1: true,
        password2: true,
        password1Val : "",
        password2Val : "",
        token: "",
        errMsg: "",
        successMsg: ""
    }

    componentDidMount(){
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.setState({'token': params.token})
    }

    handleChange = (elemKey, { currentTarget:input }) => {
        var elem = document.getElementById(elemKey);
        let type = elem.getAttribute('type');
        elem.type = type == 'password' ? 'text' : 'password';
        //this.setState({[elemKey]: input.value == 'on' ? false : true});
    }

    handlePassChange = (elemKey, {currentTarget:input}) => {
        this.setState({[elemKey]: input.value});
    }

    handleSubmit = async(e) =>{
        const password = this.state.password1Val;
        const confirm_password = this.state.password2Val;
        if(password.length < 8){
            this.setState({errMsg: 'Password must me 8 characters long'});
            //return false;

        }else if(password !== confirm_password){
            this.setState({errMsg: 'Password Confirmation does not match'});
            //return false;

        }else{
            this.setState({errMsg: ""});
            const response = await resetPassword(password, this.state.token);
            if(response.status == true){
                this.setState({successMsg: response.message});
                localStorage.setItem('token',  response.data.api_token);
                localStorage.setItem('user',  JSON.stringify(response.data));
                window.location = '/calender';
            }else{
                this.setState({errMsg: response.message});
                //return false;
            }
        }

        setTimeout(() =>{
            this.setState({'successMsg': ''});
            this.setState({'errMsg': ''});
        }, 10000);
    }

    render(){
        const style = {
            fill: 'red'
        }
        return(
            <section className="change-password">
                <div className="container">
                    <div className="foam">
                        <div className="img-cont">
                            <img src={logo} className="img-fluid" />
                        </div>
                        <div className="text-cont">
                            <h2>Set New Password</h2>
                            <p>Please set your new passowrd</p>
                        </div>
                        <div className="user-login">
                        
                            {this.state.errMsg && <div className="alert alert-danger">{this.state.errMsg}</div>}
                            {this.state.successMsg && <div className="alert alert-success">{this.state.successMsg}</div>}

                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon1">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6023 6.16318V7.44096C16.0376 7.88897 17.0833 9.18828 17.0833 10.7402V14.8543C17.0833 16.7755 15.4905 18.3332 13.5268 18.3332H6.474C4.50946 18.3332 2.91666 16.7755 2.91666 14.8543V10.7402C2.91666 9.18828 3.96329 7.88897 5.39774 7.44096V6.16318C5.4062 3.67883 7.46389 1.6665 9.9873 1.6665C12.5446 1.6665 14.6023 3.67883 14.6023 6.16318ZM10.0042 3.11571C11.7232 3.11571 13.1204 4.4821 13.1204 6.16318V7.26126H6.8796V6.14661C6.88807 4.47382 8.28526 3.11571 10.0042 3.11571ZM10.7409 13.7123C10.7409 14.1181 10.4107 14.441 9.99576 14.441C9.58931 14.441 9.25906 14.1181 9.25906 13.7123V11.8739C9.25906 11.4764 9.58931 11.1534 9.99576 11.1534C10.4107 11.1534 10.7409 11.4764 10.7409 11.8739V13.7123Z" fill="#EE3124"/>
                                    </svg>
                                </span>

                                <input type="checkbox" id="new-password" className="show-password" defaultChecked={this.state.password1}  onChange={e=>this.handleChange('password1', e)}/>
                                <label htmlFor="new-password" className="Control-label Control-label--showPassword" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32" height="32" className="svg-toggle-password" title="Toggle Password Security">
                                        <title>Hide/Show Password</title>
                                        <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z"/>
                                        <rect x="20.133" y="2.117" height="44" transform="translate(23.536 -8.587) rotate(45)" className="closed-eye"/>
                                        <rect x="22" y="3.984" width="4" height="44" transform="translate(25.403 -9.36) rotate(45)" style={style} className="closed-eye"/>
                                    </svg>
                                </label>
                                <input type="password" id="password1" placeholder="New Password" autoComplete="off" autoCapitalize="off" 
                                    autoCorrect="off" className="ControlInput ControlInput--password"
                                    onChange={e=>this.handlePassChange('password1Val', e)} 
                                />
                            </div>

                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon2">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.6023 6.16318V7.44096C16.0376 7.88897 17.0833 9.18828 17.0833 10.7402V14.8543C17.0833 16.7755 15.4905 18.3332 13.5268 18.3332H6.474C4.50946 18.3332 2.91666 16.7755 2.91666 14.8543V10.7402C2.91666 9.18828 3.96329 7.88897 5.39774 7.44096V6.16318C5.4062 3.67883 7.46389 1.6665 9.9873 1.6665C12.5446 1.6665 14.6023 3.67883 14.6023 6.16318ZM10.0042 3.11571C11.7232 3.11571 13.1204 4.4821 13.1204 6.16318V7.26126H6.8796V6.14661C6.88807 4.47382 8.28526 3.11571 10.0042 3.11571ZM10.7409 13.7123C10.7409 14.1181 10.4107 14.441 9.99576 14.441C9.58931 14.441 9.25906 14.1181 9.25906 13.7123V11.8739C9.25906 11.4764 9.58931 11.1534 9.99576 11.1534C10.4107 11.1534 10.7409 11.4764 10.7409 11.8739V13.7123Z" fill="#EE3124"/>
                                    </svg>
                                </span>
                                <input type="checkbox" id="confirm-password" className="show-password" defaultChecked={this.state.password2} onChange={e=>this.handleChange('password2', e)} />
                                <label htmlFor="confirm-password" className="Control-label Control-label--showPassword" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32" height="32" className="svg-toggle-password" title="Toggle Password Security">
                                        <title>Hide/Show Password</title>
                                        <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z"/>
                                        <rect x="20.133" y="2.117" height="44" transform="translate(23.536 -8.587) rotate(45)" className="closed-eye"/>
                                        <rect x="22" y="3.984" width="4" height="44" transform="translate(25.403 -9.36) rotate(45)" style={style} className="closed-eye"/>
                                    </svg>
                                </label>
                                <input type="password" id="password2" placeholder="Confirm Password" autoComplete="off" autoCapitalize="off" 
                                    autoCorrect="off" className="ControlInput ControlInput--password"
                                    onChange={e=>this.handlePassChange('password2Val', e)} 
                                />
                            </div>
                            <div className="btn-login">
                                <button type="button" className="btn btn-danger" onClick={this.handleSubmit}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ResetPassword;