import parse from 'html-react-parser';

export default function StatsTabItem (props){
    const weekDays = props.weekDays;
    
    let listItems = "";
    weekDays.map((weekDay, index)=>{
        let activeClass = '';
        let showClass = '';
        if(weekDay.current_day == true){   
            activeClass = 'active';
            showClass = 'show'
        }
        listItems += `
        <li className="nav-item">
            <a id="tab-${index}" href="#pos-${index}" title=${weekDay.date} className="nav-link ${activeClass}" data-bs-toggle="tab" role="tab">
                <div className="number">${weekDay.day}</div>
                <div className="day">${weekDay.week_day}</div>
            </a>
        </li>
        `
    });
     
    return (parse(listItems));
}